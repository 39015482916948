<template lang="pug">
div

  ListSchedule(
    :items="items"
    @select="select"
  )
    template(slot="line1" slot-scope="{ item }")
      v-layout(row)
        v-flex(xs6) {{item.dashboard.designName}}
        v-flex(xs6) prod date: {{formatDate(item.dates.schedule)}}
</template>

<script>
import ListSchedule from './list'
import listSort from '../../../helpers/sort-art-pending'
import moment from 'moment'

export default {
  components: { ListSchedule },
  computed: {
    items () {
      const items = this.$store.getters['productionInstances/items']
      return listSort(items)
    }
  },
  methods: {
    select (item) {
      this.$router.push('/jobs/' + item.job)
    },
    formatDate (date) {
      if (date) {
        return moment(date).format('LL')
      } else {
        return 'unscheduled'
      }
    }

  },
  async created () {
    await this.$store.dispatch('dashboards/_LOAD_ART_PENDING')
  }
}
</script>
