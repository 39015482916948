<template lang="pug">
div()
  v-list(subheader two-line)
    div(
      v-for='(item, i) in items'
      :key='item._id'
    )
      v-list-tile(
        avatar
      ).list-item
        v-list-tile-avatar(v-if="item.dashboard.image.url" tile @click="select(item)")
          img(:src="item.dashboard.image.url")
        v-list-tile-content(@click="select(item)")
          v-list-tile-title
            slot(name="line1" :item="item")
          v-list-tile-sub-title
            slot(name="line2" :item="item")

</template>
<script>

export default {
  props: {
    items: { type: Array }
  },
  computed: {
    currentUser () { return this.$store.state.users.item }
  },
  methods: {
    select (item) { this.$emit('select', item) }
  }
}
</script>
