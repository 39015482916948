export default (inputList) => {
  const items = inputList.map(x => x)

  // first sort items by date and then priority
  let itemsSorted = items.sort((a, b) => {
    const A = a._sortSchedule
    const B = b._sortSchedule
    if (A > B) return 1
    if (A < B) return -1
    return 0
  })

  // find unique
  const seen = []
  itemsSorted.forEach(item => {
    let found = seen.find(el => item.design === el.design)
    if (found === undefined) { seen.push(item) }
  })
  itemsSorted = seen

  return itemsSorted
}
